<script>
import  {countDiff} from "@/utils/time";
import InputDate from "@/components/forms/InputDate.vue";
export default {
  components: {
    InputDate,
  },
  data() {
    return {
      hora: '00',
      min: '00',
      seg: '00',
      day: "",
      dayInit: new Date(),
      tite: "",
      currentTime: new Date(),
      timer: null,
    };
  },
  props: {
    countDownData: {
      type: Array,
      required: false,
      default: () => ([]),
    }
  },
  created() {
    const cd = this.countDownData[0]
    if(cd && cd.titulo !== undefined){
      this.tite = cd.titulo
      this.day = cd.fecha
      this.dayInit = new Date(cd.fecha+"T00:00:00")
      this.day = new Date(cd.fecha+"T00:00:00").toISOString().split('T')[0]
      const [h, m, s] = cd.hora.split(":")
      this.hora = Number(h)
      this.min = Number(m)
      this.seg = Number(s)
    }else{
      this.day = new Date().toISOString().split('T')[0]
    }
  },
  computed: {
    countDown() {
      const hr = `${this.lPadZ(this.hora | '0')}:${this.lPadZ(this.min | '0')}:${this.lPadZ(this.seg | '0')}`;
      if (!this.day) {
        return [0, 0, 0, 0, 0];
      }
      const targetDateTime = new Date(
        `${this.day}T${hr}`
      );
      const now = this.currentTime;
      
      return countDiff(now, targetDateTime);
    },
  },
  methods: {
    captureDate(date) {
      this.day = date? date.toISOString().split('T')[0] : "";
    },
    titleClear(){
      this.tite = this.tite.trim()
    },
    setSelectedTime() {
      const titulo = this.tite
      const fecha = this.day;
      this.hora = this.lPadZ(this.hora | '0')
      this.min = this.lPadZ(this.min| '0')
      this.seg = this.lPadZ(this.seg| '0')

      const hora = `${this.hora}:${this.min}:${this.seg}`
      this.$emit("setSelectedTime", {
        titulo,
        fecha,
        hora,
      });
    },
    lPadZ(n){
      return (n < 10 ? "0" + n : n);
    }
  },
  watch: {
    hora(newVal, oldVal) {
      if(newVal == '') return
      const num = Number(newVal)
      if(!Number.isInteger(num)){
        this.hora = oldVal
        return
      }
      if (num < 0 || num > 23) {
        this.hora = oldVal !== undefined ? oldVal : '';
        return
      }
    },
    min(newVal, oldVal) {
      if(newVal == '') return
      const num = Number(newVal)
      if(!Number.isInteger(num)){
        this.min = oldVal
        return
      }
      if (num < 0 || num > 59) {
        this.min = oldVal !== undefined ? oldVal : '';
        return
      }
    },
    seg(newVal, oldVal) {
      if(newVal == '') return
      const num = Number(newVal)
      if(!Number.isInteger(num)){
        this.seg = oldVal
        return
      }
      if (num < 0 || num > 59) {
        this.seg = oldVal !== undefined ? oldVal : '';
        return
      }
    },
    day() {
      this.setSelectedTime()
    },
    tite() {
      this.setSelectedTime()
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<template>
  <div>
    <section class="card">
      <div class="card-body pb-0">
        <div class="row">
          <div class="col-12">
            <h6 class="font-main border-bottom text-secondary pb-1 mb-3">
              Título de la cuenta regresiva
            </h6>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex align-items-center">
            <input
              type="text"
              v-model="tite"
              class="form-control input-custom"
              placeholder="Título"
              @focusout="titleClear"
            />
          </div>
        </div>
      </div>
      <div class="card-body pb-0">
        <div class="row">
          <div class="col-12">
            <h6 class="font-main border-bottom text-secondary pb-1 mb-3">
              Fecha
            </h6>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <InputDate
              type="text"
              :initialDate="dayInit"
              :min="currentTime"
              @dateEmit="captureDate"
              placeHolder="Dia de la cuenta regresiva"
            />
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h6 class="font-main border-bottom text-secondary pb-1 mb-3">
              Hora
            </h6>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex gap-2 align-items-center">
            <input
              type="number"
              max="23"
              min="0"
              class="form-control input-custom text-center"
              placeholder="Hora"
              v-model="hora"
              @focusout="setSelectedTime()"
            />
            <p class="m-0">:</p>
            <input
              type="number"
              max="59"
              min="0"
              class="form-control input-custom text-center"
              placeholder="Minutos"
              v-model="min"
              @focusout="setSelectedTime()"
            />
            <p class="m-0">:</p>
            <input
              type="number"
              max="59"
              min="0"
              class="form-control input-custom text-center"
              placeholder="Segundos"
              v-model="seg"
              @focusout="setSelectedTime()"
            />
          </div>
        </div>
      </div>
    </section>
    <br />
    <section class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h6 class="font-main border-bottom text-secondary pb-1 mb-3">
              Cuenta regresiva
            </h6>
          </div>
        </div>

        <div class="row">
          <article
            class="col-12 gap-1 d-flex justify-content-center align-items-center text-center"
          >
            <div class="col-3 col-lx-2">
              <h4 class="m-0">{{countDown[1]}}</h4>
              <small>Días</small>
            </div>
            <div class="col-3 col-lx-2">
              <h4 class="m-0">{{countDown[2]}}</h4>
              <small>Horas</small>
            </div>
            <div class="col-3 col-lx-2">
              <h4 class="m-0">{{countDown[3]}}</h4>
              <small>Minutos</small>
            </div>
            <div class="col-3 col-lx-2">
              <h4 class="m-0">{{countDown[4]}}</h4>
              <small>Segundos</small>
            </div>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>
